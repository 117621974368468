p {
  font-family: "Rubik", sans-serif;
  font-size: 1em;
  line-height: 2;
  word-spacing: 2px;
  letter-spacing: 1px;
  /*background-color: #3A98B9;*/
}

.app-layout {
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  min-height: 100vh;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /*grid-row: 1 / 2;
  grid-column: 1 / 4;*/
  padding-inline: 30px;
  border-bottom: 3px solid #3a98b9;
  margin-bottom: 20px;
}

h2 {
  font-family: "Tilt Warp", cursive;
  font-size: 2.5em;
}

.main-content {
  display: flex;
  margin-right: 100px;
}

.description {
  font-size: 0.8em;
  color: gray;
  padding-inline: 50px;
  line-height: 1.5em;
  margin-bottom: 20px;
}

.app-content {
  /*grid-row: 2 / 3;
  grid-column: 2 / 3;*/
  padding: 10px;
}

.app-footer {
  /*grid-row: 3 / 4;
  grid-column: 2 / 3;*/
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: 50px;
  border-top: 1px solid #3a98b9;
}

.footer-nav {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
}

.header-title {
  font-family: "Tilt Warp", cursive;
  font-size: 3em;
  margin: 8px;
  color: #3a98b9;
}

.header-nav a {
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-size: 1.5em;
  padding: 0.5em;
  color: black;
}

.header-nav {
  margin: 8px;
}

.header-nav a:hover {
  background-color: #3a98b9;
  border-radius: 0.5em;
  color: white;
}

.header-nav ol {
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
}

.header-nav li {
  display: inline;
}

.footer-nav a {
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-size: 1.5em;
  color: black;
}

.footer-nav a:hover {
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-size: 1.5em;
  color: #3a98b9;
}

/*----------------------Formulaire---------------------------*/
.form {
  padding: 10px;
  background-color: #3a97b900;
  display: flex;
  align-items: start;
  justify-content: center;
}

.form fieldset {
  font-family: "Rubik", sans-serif;
  font-size: 1.2em;
  padding-inline: 50px;
  padding-block: 50px;
  margin: 50px;
  width: 500px;
  border-color: #3a98b9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
}

.form textarea {
  height: 150px;
}

.form input {
  background-color: #3a97b92a;
  border: 2px solid #3a98b9;
  padding: 10px;
  font-size: 1.2em;
  font-family: "Rubik", sans-serif;
  border-radius: 5px;
}

.form input:focus {
  background-color: white;
}

.form input[type="submit"] {
  border: 2px solid #3a98b9;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 1.2em;
  font-family: "Rubik", sans-serif;
  background-color: white;
  cursor: pointer;
}

.form input[type="submit"]:hover {
  background-color: #3a98b9;
  color: white;
}

.popover {
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  padding: 5px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 20px;
  top: 30px;
  font-size: 1em;
  min-width: 200px;
  text-align: center;
}

.popover_wrapper:hover .popover {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: all 1s;
}

.popover_wrapper {
  position: relative;
}

.input-file {
  font-size: 1;
  font-family: "Rubik", sans-serif;
}
